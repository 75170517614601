<template lang="">
    <div>
        <List />
    </div>
</template>
<script>
export default {
    components:{
        List : () =>import("@/components/manage/location/LocationList")
    }
}
</script>
<style lang="">
    
</style>